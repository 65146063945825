import React, { useState, ReactNode } from "react";
import { Text, Flex } from "flicket-ui";
import styled from "styled-components";
import { IconProps, Icon } from "../Icon/Icon";

export const Tab = styled(Flex).attrs({
  as: "button",
  px: 4,
  py: "6/4",
  alignItems: "center",
})<{
  active: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: all 0.05s ease;
  background-color: transparent;

  ${(p) =>
    p.active &&
    `
    background-color: white;
    color: ${p.theme.colors.P300};
  `};

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 6px;
    height: 6px;
    background-color: white;
    z-index: 0;
    opacity: ${(p) => (p.active ? 1 : 0)};
    transition: all 0.05s ease;
    transition-delay: 0.05s;
  }

  &:before {
    left: -6px;
  }
  &:after {
    right: -6px;
  }

  span:before,
  span:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 15px;
    height: 15px;
    background-color: ${(p) => p.theme.colors.N100};
    border-radius: 50%;
    z-index: 1;
    opacity: ${(p) => (p.active ? 1 : 0)};
    transition: all 0.05s ease;
    transition-delay: 0.05s;
  }
  span:before {
    left: -15px;
    ${(p) => p.isFirst && `border-radius: 0;`}
  }
  span:after {
    right: -15px;
    ${(p) => p.isLast && `border-radius: 0;`}
  }
`;

export interface TabItem {
  name: string;
  icon?: IconProps["icon"];
  content: ReactNode;
  key?: string;
  [x: string]: any;
}

interface FolderTabsProps {
  activeIndex?: number;
  initialActiveIndex?: number;
  items: TabItem[];
  onTabChange?: (index: number) => any;
}

export const FolderTabs = ({
  items,
  activeIndex: activeIndexProp,
  initialActiveIndex,
  onTabChange,
}: FolderTabsProps) => {
  const [activeIndexState, setActiveIndex] = useState(initialActiveIndex || 0);
  const activeIndex =
    activeIndexProp !== undefined ? activeIndexProp : activeIndexState;

  const handleTabChange = (index: number) => {
    if (onTabChange) onTabChange(index);
    setActiveIndex(index);
  };

  return (
    <>
      <Flex flexWrap={"wrap"}>
        {items.map((tab, index) => (
          <Tab
            key={tab.name}
            onClick={() => {
              !tab.disabled && handleTabChange(index);
            }}
            active={activeIndex === index}
            isFirst={index === 0}
            isLast={index === items.length}
          >
            <Text
              as="span"
              variant="extraBold.M"
              color={tab.disabled ? "N300" : ("inherit" as any)}
            >
              {tab.name}
            </Text>

            {tab.icon && (
              <Icon
                icon={tab.icon as any}
                ml={2}
                color={tab.icon === "check-circle" ? "success" : "warning"}
                fontSize={3}
              />
            )}
          </Tab>
        ))}
      </Flex>

      <Flex
        bg="white"
        borderRadius={"12px" as any}
        width={1}
        flexDir="column"
        p={3}
        borderTopLeftRadius={activeIndex === 0 ? "none" : ("12px" as any)}
        zIndex={1}
        style={{ transition: "all .2s ease" }}
        flex={1}
      >
        {!items[activeIndex].disabled && items[activeIndex].content}
      </Flex>
    </>
  );
};

export const getValidTabIcon = (isValid: boolean) =>
  isValid ? "check-circle" : "error";

export const validateTabAndGetIcon: <T extends (...args) => boolean>(
  func: T
) => (...func: Parameters<T>) => ReturnType<typeof getValidTabIcon> = (
  func
) => (...params) => getValidTabIcon(func(...params));
