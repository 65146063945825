import React, { HTMLProps, forwardRef, WheelEvent, ReactNode } from "react";
import { omit, pick } from "@styled-system/props";
import { SystemProps, InputWrapper } from "flicket-ui";
import styled from "styled-components";
import { padding, PaddingProps, height, HeightProps } from "styled-system";

interface StyledInputProps extends PaddingProps, HeightProps {
  isValid?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  padding: 12px 16px;

  color: ${(p) => p.theme.colors.N800};
  font-size: ${(p) => p.theme.fontSizes[3]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  letter-spacing: -0.165px;
  line-height: 150%;
  max-height: 100%;

  border: 1px solid ${(p) => p.theme.colors[!p.isValid ? "error" : "N200"]};

  border-radius: ${(p) => p.theme.radii.sm};

  box-shadow: ${(p) => p.theme.shadows.inner.sm};

  &:focus {
    border-color: ${(p) => p.theme.colors[!p.isValid ? "error" : "N500"]};
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.N400};
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors.N100};
  }

  ${padding}
  ${height}
`;
type FieldComponent = Omit<
  HTMLProps<HTMLInputElement>,
  "color" | "as" | "ref" | "width"
>;

export interface InputProps extends Omit<FieldComponent, "label"> {
  error?: string;
  errors?: any;
  label?: ReactNode;
  inputAs?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps & SystemProps>(
  ({ label, error, errors, name, inputAs, padding, height, ...props }, ref) => {
    error = error || errors?.[name]?.message;
    const handleOnWheel = (e: WheelEvent<HTMLInputElement>) => {
      if (e.currentTarget.type === "number") {
        e.currentTarget.blur();
      }
    };
    return (
      <InputWrapper {...pick(props)} name={name} label={label} error={error}>
        <StyledInput
          as={inputAs}
          ref={ref}
          name={name}
          id={name}
          isValid={!error}
          {...omit(props)}
          padding={padding}
          height={height}
          onWheel={handleOnWheel}
        />
      </InputWrapper>
    );
  }
);

Input.displayName = "Input";
