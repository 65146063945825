import ReactTooltip from "react-tooltip";
import { Icon } from "~components";
import { IconProps } from "../Icon/Icon";

export const TooltipIcon = ({
  description,
  variant = "info",
  icon,
}: {
  description: string;
  variant?: "info" | "warning" | "error";
  icon?: IconProps["icon"];
}) => {
  const colour = (() => {
    if (variant === "info") {
      return undefined;
    }

    return variant;
  })();

  const iconName = (() => {
    switch (variant) {
      case "info":
        return "info";
      case "warning":
        return "warning-circle";
      case "error":
        return "error";
    }
  })();

  return (
    <>
      <Icon
        data-tip={description}
        icon={icon ?? iconName}
        ml={1}
        fontSize={3}
        mr={1}
        color={colour}
      />
      <ReactTooltip
        effect="solid"
        css={{
          fontWeight: "normal",
        }}
        textColor={"black"}
        borderColor="gray"
        backgroundColor="lightgray"
        multiline={true}
      />
    </>
  );
};
