import { get } from "lodash";

const errorLocations = {
  graphQL: "response.errors",
  axios: "response.data.message",
  status: "err.status",
  default: "err.response.data",
  yup: "",
};

type errorType = keyof typeof errorLocations;

export const getError = (err: any, type: errorType = "graphQL"): string => {
  if (type === "yup") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      Object.values(err)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map(({ message }) => message)
        .join("&nbsp;")
    );
  }
  const errorLocation = errorLocations[type];

  let message = get(err, errorLocation, "An unknown error has occured");

  if (Array.isArray(message)) {
    message = message[0]?.message || message[0];
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return message as string;
};
