enum variants {
  Hidden = "hidden",
  Show = "show",
}

export const variantOptions = {
  [variants.Hidden]: {
    y: -20,
    transition: {
      y: { duration: 0.16, ease: "easeIn" },
    },
  },
  [variants.Show]: {
    y: 0,
    transition: {
      y: { delay: 0.16, duration: 0.2, ease: "easeOut" },
    },
  },
};

export const modalMotion = {
  variants: variantOptions,
  initial: variants.Hidden,
  animate: variants.Show,
  exit: variants.Hidden,
};
